<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    :dark="app.isDark"
    :style="styles"
    floating
    mobile-breakpoint="991"
    persistent
    width="300"
    color="primary"
  >
    <v-list-item two-line class="my-3">
      <v-list-item-title class="text-h6">
        <router-link to="/overview">
          <v-img
            class=""
            src="@/assets/logo-bouygues-telecom-entreprises-white.png"
            height="80px"
            contain
          />
        </router-link>
        <v-img
          class="mt-1"
          src="@/assets/powered_by_hmd_white.png"
          height="24px"
          position="center"
          contain
        />
      </v-list-item-title>
    </v-list-item>

    <v-list-item class="my-3 mx-6 pa-2 user_square">
      <v-row align="end" class="justify-content-center">
        <v-col cols="3" class="align-items-center mx-2">
          <v-badge bottom dot color="green">
            <v-btn icon class="transparent" :ripple="false" to="/profile">
              <v-icon large>mdi-account-circle</v-icon>
            </v-btn>
          </v-badge>
        </v-col>
        <v-spacer></v-spacer>
        <v-col align-self="center" cols="8">
          <v-row>{{ profile.first_name + " " + profile.last_name }}</v-row>
          <v-row class="text-caption">{{ formatUserGroup() }}</v-row>
        </v-col>
      </v-row>
    </v-list-item>

    <v-divider class="mt-4 mx-3 mb-2" />

    <v-list flat nav>
      <!-- Bug in Vuetify for first child of v-list not receiving proper border-radius -->
      <div />

      <v-list-item
        v-for="(link, i) in filteredLinks"
        :key="i"
        :to="link.to"
        active-class="white--text font-weight-bold nav_links"
        class="mx-2 px-4 py-1"
        :ripple="!($route.path.includes(link.to) && $route.path !== link.to)"
        @click="closeActiveGroups()"
      >
        <v-list-item-action>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title v-text="link.text" />
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        v-for="(link, i) in filteredLinksWithChildren"
        :key="(i + 1) * 10"
        v-model="groupStates[`group${i}`]"
        active-class="white--text nav_links"
        class="mx-1 px-1"
      >
        <template v-slot:prependIcon>
          <v-icon class="ml-2">{{ link.icon }}</v-icon>
        </template>
        <template v-slot:activator>
          <v-list-item-title class="ml-2">{{ link.text }}</v-list-item-title>
        </template>

        <div class="nav_links py-1 rounded mb-2">
          <v-list-item
            v-for="(child, j) in link.children"
            :key="(i + 1) * 10 + j"
            :to="link.to + child.to"
            class="submenu-item transparent my-5"
            active-class="white--text submenu-item-active"
            :ripple="false"
          >
            <v-list-item-title class="submenu-text px-6" v-text="child.text" />
          </v-list-item>
        </div>
      </v-list-group>
    </v-list>

    <template v-slot:append>
      <v-list nav>
        <v-list-item v-if="isDebug">
          <!-- TODO avant prod, supprimer composant -->
          <v-icon>mdi-account</v-icon>

          <v-list-item-title class="font-weight-light">
            <v-select
              v-model="selectedGroup"
              :items="groups"
              @change="changeGroup"
            />
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="handleLogout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>

          <v-list-item-title class="font-weight-light">
            Déconnexion
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import AccountService from "@/services/AccountService";
import DrawerLinks from "@/utils/drawer-links";
import { logout } from "@/utils/auth";
import Constant from "@/constant";

import { mapMutations, mapState } from "vuex";

const Config = require("@/utils/theme-config");
export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    app: {
      logo: Config.app.logo,
      isDark: Config.drawer.isDark
    },
    isDebug: Constant.DEBUG,
    groups: [...Constant.groups()],
    groupStates: {},
    selectedGroup: null,
    links: [],
    loading: false,
    profileCopy: {
      email: "",
      first_name: "",
      last_name: ""
    },
    profile: {
      email: "",
      first_name: "",
      last_name: ""
    },
    subMenuOpened: {}
  }),
  computed: {
    styles() {
      const temp = {};
      return {
        background:
          Config.drawer.bgColor[
            this.$store.getters.isUserAdmin ? "admin" : "default"
          ]
      };
    },
    ...mapState("app", ["image", "color"]),
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      }
    },
    filteredLinks() {
      return this.links.filter(l => !l.hide).filter(l => !l.children);
    },
    filteredLinksWithChildren() {
      return this.links.filter(l => !l.hide).filter(l => l.children);
    }
  },
  created() {
    var t = this;
    this.loading = true;

    this.filteredLinksWithChildren.forEach((_, index) => {
      this.$set(this.groupStates, `group${index}`, false);
    });

    AccountService.profile(this)
      .then(function(response) {
        t.profile = response;
        t.profileCopy = t.lo.cloneDeep(response);
      })
      .finally(() => {
        t.loading = false;
      });
  },
  mounted() {
    this.selectedGroup = this.$store.getters.group;
    this.links = DrawerLinks.generateLink(this.selectedGroup);
    this.links.forEach(link => {
      this.$set(this.subMenuOpened, link.to, { opened: false });
    });
  },
  methods: {
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    handleLogout() {
      logout();
    },
    changeGroup() {
      this.$store.commit("setGroup", this.selectedGroup);
      this.links = DrawerLinks.generateLink(this.selectedGroup);
    },
    openChildren(link) {
      if (link.children) {
        this.subMenuOpened[link.to].opened = !this.subMenuOpened[link.to]
          .opened;
      }
    },
    childrenActive(link) {
      if (link.children) {
        return link.children.some(
          child => this.$route.path === link.to + child.to
        );
      }
      return this.$route.path === link.to;
    },
    formatUserGroup() {
      if (this.selectedGroup === null) {
        return "";
      }
      const groupNames = {
        "BTE-Utilisateur": "Bouygues Utilisateur",
        "BTE-Admin": "Bouygues Admin",
        "BTE-SuperAdmin": "Bouygues Super Admin",
        Plurium: "Plurium"
      };
      const group = groupNames[this.selectedGroup];
      return group;
    },
    closeActiveGroups() {
      Object.keys(this.groupStates).forEach(key => {
        this.groupStates[key] = null;
      });
    }
  }
};
</script>

<style scoped>
.submenu-text {
  font-size: 0.9rem;
}

.submenu-item {
  height: 20px !important;
  min-height: 20px !important;
}

.transparent::before {
  background-color: transparent !important;
}

.transparent:hover {
  font-weight: bolder;
}

.submenu-item-active {
  font-weight: bold !important;
}
</style>
